<template>
  <div>
    <!-- Clear Selected search criteria and companies,  Selected Search Criteria-->
    <vx-card class="mb-8">
      <div class="card-title more-card-title flex justify-between items-center">
        <div class="flex items-center flex-wrap">
          <h2 class="w-auto mb-2"> {{ LabelConstant.headingLabelSelectedCriteria }} </h2>
          <p class="table-data-entries-details text-left mb-2"> {{ LabelConstant.LabelTotalCompanies }}:
            <span>{{ totalCompanies | tableRecordNumberFormatter }}</span>
          </p>
        </div>
        <!-- Clear Selected search criteria and companies -->
        <div class="flex items-center flex-wrap">
          <a :href="chromeExtensionLink" target="_blank" v-if="!this.$store.state.isReseller">
            <vs-button type="filled" class="bg-actionbutton mr-2">{{ LabelConstant.buttonLabelDownloadChromeExtension }}</vs-button>
          </a>
          <vs-button
            color="grey"
            type="border"
            class="grey-btn sm:mt-0 mt-2 ml-2"
            icon-pack="feather"
            icon="icon-refresh-ccw"
            @click="clearSearchCriteria()"
            >{{ LabelConstant.buttonLabelClearSearchCriteria }}
          </vs-button>
        </div>
      </div>
      <!-- Selected Search Criteria -->
      <div class="vx-row find-people">
        <div class="vx-col w-full">
          <input-tag v-model="tags" @input="testUpdateSelectedTags"></input-tag>
        </div>
      </div>
    </vx-card>

    <!-- Search Criteria Filter, companies Table -->
    <vx-card>
      <div class="vx-row find-people">
        <!-- Search Criteria Filter -->
        <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full">
          <div class="px-0 search-filters">
            <div slot="header" class="filter-header pl-0">
              {{ LabelConstant.headingLabelSearchCriteria }}
            </div>
            <vs-collapse accordion class="px-0">
              <!-- Firmagraphic  -->
              <div class="filter-card">
                <vs-collapse-item>
                  <div slot="header">
                    {{ LabelConstant.headingCollapsableLabelFirmographic }}
                  </div>
                  <div class="filter-list">
                    <vs-checkbox v-model="showCompanyNameText" @click="companyName = []" >{{ LabelConstant.inputLabelName }}</vs-checkbox>
                    <div v-if="showCompanyNameText">
                      <input-tag v-model="companyName" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(companyName,'companyName')"></input-tag>
                    </div>
                    <vs-checkbox v-model="showCompanyWebAddressText" @click="companyWebAddress = []">{{ LabelConstant.inputLabelWebsite }}</vs-checkbox>
                    <div v-if="showCompanyWebAddressText">
                      <input-tag v-model="companyWebAddress" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(companyWebAddress,'companyWebAddress')"></input-tag>
                    </div>
                    <vs-checkbox v-model="showCompanyIndustryOptions" @click="companyIndustry = []" >{{ LabelConstant.inputLabelIndustry }}</vs-checkbox>
                    <div v-if="showCompanyIndustryOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="companyIndustryOptions"
                        v-model="companyIndustry"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showCompanySICSOptions" @click="companySICS = []">{{ LabelConstant.inputLabelSICCode }}</vs-checkbox>
                    <div v-if="showCompanySICSOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="companySICSOptions"
                        v-model="companySICS"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showCompanyNAICSOptions" @click="companyNAICS = []" >{{ LabelConstant.inputLabelNAICSCode }}</vs-checkbox>
                    <div v-if="showCompanyNAICSOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="companyNAICSOptions"
                        v-model="companyNAICS"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showCompanyEmployeeSizeOptions" @click="companyEmployeeSize = []" >{{ LabelConstant.inputLabelEmployeeSize }}</vs-checkbox>
                    <div v-if="showCompanyEmployeeSizeOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="companyEmployeeSizeOptions"
                        v-model="companyEmployeeSize"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showCompanyRevenueBandsOptions" @click="companyRevenueBands = []" >{{ LabelConstant.inputLabelRevenueBand }}</vs-checkbox>
                    <div v-if="showCompanyRevenueBandsOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="companyRevenueBandsOptions"
                        v-model="companyRevenueBands"
                        multiple
                      />
                    </div>
                  </div>
                </vs-collapse-item>
              </div>
              <!-- Location   -->
              <div class="filter-card">
                <vs-collapse-item>
                  <div slot="header">
                    {{ LabelConstant.inputLabelLocation }}
                  </div>
                  <div class="filter-list">
                    <!-- <vs-checkbox v-model="showContactRegionOptions" @click="contactRegion = []">Region</vs-checkbox>
                    <div v-if="showContactRegionOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="contactRegionOptions"
                        v-model="contactRegion"
                        multiple
                      />
                    </div> -->
                    <vs-checkbox v-model="showLocationTypeOptions" @click="locationType = []" >{{ LabelConstant.inputLabelLocationType }}</vs-checkbox>
                    <div v-if="showLocationTypeOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="locationTypeOptions"
                        v-model="locationType"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showContactCountriesOptions" @click="contactCountries = []" >{{ LabelConstant.inputLabelCountry }}</vs-checkbox>
                    <div v-if="showContactCountriesOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="contactCountriesOptions"
                        v-model="contactCountries"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showContactSatesOptions" @click="contactStates = []" >{{ LabelConstant.inputLabelState }}</vs-checkbox>
                    <div v-if="showContactSatesOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="contactStatesOptions"
                        v-model="contactStates"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showContactCity" @click="contactCity = []" >{{ LabelConstant.inputLabelCity }}</vs-checkbox>
                    <div v-if="showContactCity">
                      <input-tag v-model="contactCity" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactCity,'contactCity')"></input-tag>
                    </div>
                    <vs-checkbox v-model="showContactZipCode" @click="contactZipCode = []">{{ LabelConstant.inputLabelZip }}</vs-checkbox>
                    <div v-if="showContactZipCode">
                      <input-tag v-model="contactZipCode" :addTagOnBlur="true" :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]" @input="termSeparation(contactZipCode,'contactZipCode')"></input-tag>
                    </div>
                  </div>
                </vs-collapse-item>
              </div>
            </vs-collapse>
          </div>
          <!-- buttons -->
          <div class="pt-5 mt-5 flex">
            <vs-button
              :title="LabelConstant.buttonLabelSearch"
              color="primary"
              type="filled"
              class="mr-1 px-5"
              @click="searchCompanies()"
              >{{ LabelConstant.buttonLabelSearch }}
            </vs-button>
          </div>
        </div>
        <!-- search-companies table-->
        <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left">
          <div>
            <vs-navbar class="nabarx mb-8 table-nav">
              <div slot="title">
                <vs-navbar-title>
                  {{ LabelConstant.headingLabelFindCompanies }}
                </vs-navbar-title>
              </div>
              <vs-navbar-item index="0">
                <dropdown-menu class="dropdown-export">
                  <button slot="trigger" class="dropdown-export-btn">
                    {{ LabelConstant.buttonLabelExport }}
                    <i class="vs-icon notranslate icon-scale material-icons null">expand_more</i>
                  </button>
                  <ul slot="body">
                    <li>
                      <xlsx-workbook>
                        <xlsx-sheet
                          :collection="downloadCompany.data"
                          v-for="downloadCompany in downloadCompanies"
                          :key="downloadCompany.name"
                          :sheet-name="downloadCompany.name"
                        />
                        <xlsx-download :filename="'Companies.csv'" :options="{ bookType: 'csv' }">
                          <span @click="exportCompanies()">{{ LabelConstant.buttonLabelExportCSV }}</span>
                        </xlsx-download>
                      </xlsx-workbook>
                    </li>
                    <li >
                      <xlsx-workbook>
                        <xlsx-sheet
                          :collection="downloadCompany.data"
                          v-for="downloadCompany in downloadCompanies"
                          :key="downloadCompany.name"
                          :sheet-name="downloadCompany.name"
                        />
                        <xlsx-download :filename="'Companies.xlsx'" :options="{ bookType: 'xlsx' }">
                          <span @click="exportCompanies()">{{ LabelConstant.buttonLabelExportExcel }}</span>
                        </xlsx-download>
                      </xlsx-workbook>
                    </li>
                    <li v-if="initialRole =='Global Admin' && appRunningOnDev.toLowerCase() === 'true'" @click="CommingsoonPopup = true">
                      {{ LabelConstant.buttonLabelExportCRM }}
                    </li>
                  </ul>
                </dropdown-menu>
                <!-- <vs-dropdown>
                  <a class="a-icon" href="#">
                    {{ LabelConstant.buttonLabelExport }}
                    <vs-icon class="" icon="expand_more"></vs-icon>
                  </a>
                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <xlsx-workbook>
                        <xlsx-sheet
                          :collection="downloadCompany.data"
                          v-for="downloadCompany in downloadCompanies"
                          :key="downloadCompany.name"
                          :sheet-name="downloadCompany.name"
                        />
                        <xlsx-download
                          :filename="'Companies.csv'"
                          :options="{ bookType: 'csv' }"
                        >
                          <a @click="exportCompanies()">
                            {{ LabelConstant.buttonLabelExportCSV }}
                          </a>
                        </xlsx-download>
                      </xlsx-workbook>
                    </vs-dropdown-item>
                    <vs-dropdown-item >
                      <xlsx-workbook>
                        <xlsx-sheet
                          :collection="downloadCompany.data"
                          v-for="downloadCompany in downloadCompanies"
                          :key="downloadCompany.name"
                          :sheet-name="downloadCompany.name"
                        />
                        <xlsx-download
                          :filename="'Companies.xlsx'"
                          :options="{ bookType: 'xlsx' }"
                        >
                          <a @click="exportCompanies()"> {{ LabelConstant.buttonLabelExportExcel }} </a>
                        </xlsx-download>
                      </xlsx-workbook>
                    </vs-dropdown-item>
                    <vs-dropdown-item v-if="initialRole =='Global Admin' && appRunningOnDev.toLowerCase() === 'true'" @click="CommingsoonPopup = true">
                      {{ LabelConstant.buttonLabelExportCRM }}
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown> -->
              </vs-navbar-item>

              <v-select
                class="ml-2 show-tbl-data"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerMainPage"
                @input="searchCompaniesPaginationTimeOut(currentPage)"
              />
            </vs-navbar>
          </div>
          <!-- <div class="card-title more-card-title flex align-center">
            <div>
              <h2 class="mb-1">{{ LabelConstant.headingLabelFindCompanies }}</h2>
              <p class="lg:mb-0 sm:mb-2">
                {{ LabelConstant.infoLableRefineYourSearch }}
              </p>
            </div>
            <div> -->
          <!-- Record Per page -->
          <!-- <v-select
                class="mr-2 float-left show-tbl-data"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerMainPage"
              />
              <vs-button
                :title="LabelConstant.buttonLabelExport"
                color="primary"
                type="filled"
                icon-pack="feather"
                icon="icon-download"
                class="mr-2 float-left"
                @click="exportCompanies()"
                >{{ LabelConstant.buttonLabelExport }}</vs-button
              > -->
          <!-- <vs-button
                title="Update"
                color="warning"
                type="filled"
                class="mr-2 float-left"
                >Find Contacts at these Companies</vs-button
              > -->
          <!-- </div>
          </div> -->
          <!-- Find Companies table (Companies Table) -->
          <div class="common-table pt-0" v-if="companies && companies.length > 0">
            <!-- table -->
            <vs-table multiple v-model="selectedMain" :data="companies">
              <template slot="thead">
                <vs-th>{{ LabelConstant.tableHeaderLabelCompanyName }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelIndustry }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelEmployee }} </vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelLocation }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].CompanyName">
                    <div class="flex items-center">
                      <a
                        @click="companyDetailPopup = true, selectedCompanyId = data[indextr].CompanyId, getCompanyDetail(data[indextr].CompanyId)"
                        class="flex items-center"
                      >
                        <img
                          :src="data[indextr].CompanyLogoUrl"
                          alt="Company Logo"
                          class="inline-block mr-2"
                          height="50"
                          width="50"
                        />
                        {{ data[indextr].CompanyName }}
                      </a>
                      <!-- <feather-icon
                        class="text-primary ml-auto"
                        icon="InfoIcon"
                        title="Infomation"
                      ></feather-icon> -->
                      <a
                        v-show="data[indextr].LinkedinUrl && data[indextr].LinkedinUrl != ''"
                        :href="'https://' + data[indextr].LinkedinUrl"
                        target="_blank"
                        class="ml-auto"
                      >
                        <vs-avatar
                          color="primary"
                          icon-pack="feather"
                          icon="icon-linkedin"
                          size="28px"
                        />
                      </a>
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].Industry">
                    {{ data[indextr].Industry }}
                  </vs-td>
                  <vs-td :data="data[indextr].EmployeeCount">
                    {{ data[indextr].EmployeeCount | tableRecordNumberFormatter }}
                  </vs-td>
                  <vs-td :data="data[indextr].location">
                    {{ data[indextr].location }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- Total Companies and Pagination -->
            <div class="vx-row clear-both">
              <div class="vx-col w-full mt-4 lg:w-full">
                <div class="flex items-center justify-between paginate-row flex-wrap">
                  <p class="table-data-entries-details">
                    {{ LabelConstant.LabelTotalCompanies }}:
                    <span>{{ totalCompanies | tableRecordNumberFormatter }}</span>
                  </p>
                  <!-- <paginate
                    ref="companiesMainPaginate"
                    :page-count="totalPages"
                    :click-handler="searchCompaniesByPagination"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :page-range="3"
                    :first-last-button="true"
                    :first-button-text="'|<'"
                    :last-button-text="'>|'"
                    :container-class="'paginate'"
                  >
                  </paginate> -->
                  <!-- <paginate
                    ref="companiesMainPaginate"
                    :page-count="1"
                    :click-handler="searchCompaniesByPagination"
                    :prevText="'< Prev'"
                    :nextText="'Next >'"
                    :page-range="1"
                    :force-page="3"
                    :first-last-button="false"
                    :container-class="'paginate'"
                  >
                  </paginate> -->
                  <b-pagination
                    pills
                    size="sm"
                    class="pr-3 bootstrap-pagination"
                    v-model="currentPage"
                    :total-rows="totalCompanies"
                    :per-page="recordPerMainPage"
                    :limit="1"
                    prev-text="< Prev"
                    next-text="Next >"
                    :hide-goto-end-buttons="true"
                    @change="searchCompaniesPaginationTimeOut(currentPage)"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
          <!-- if no companies found -->
          <div v-else>
            <div class="row pt-2 no-data-found">
              <div class="col-12 text-center">
                <div class="sm:w-1/4 w-1/2 mx-auto">
                  <img
                    src="../../../src/assets/images/no-data.png"
                    class="d-inline-block w-full"
                  />
                </div>
                <h2>{{ LabelConstant.infoLabelNoCompaniesFound }}</h2>
              </div>
              <div class="col-12 text-center">
                <div class="lg:w-1/2 md:w-2/3 w-full mx-auto text-left pt-3">
                  <h6 class="py-2">{{ LabelConstant.headingLabelSuggestions }}</h6>
                  <ul class="pl-5">
                    <li>{{ LabelConstant.infoLabelTryGeneralizedSearchCriteria }}</li>
                    <li>{{ LabelConstant.infoLabelTermsSpelledCorrectly }}</li>
                    <li>{{ LabelConstant.infoLabelTryDifferentCriteria }}</li>
                    <!-- <li class="link" @click="searchTipsPopup = true">Try these search tips</li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- end if no companies found -->
        </div>
      </div>
    </vx-card>

    <!-- Select Connection -->
    <find-any-one-select-connection
      :popupTitle="'Select Connection'"
      :connectionPopup="connectionPopup"
      :pushModule="pushModule"
      :selectedMain="selectedMain"
      :selectedInner="selectedInner"
      :applicationId="5"
      @cancelConnection="connectionPopup = false,selectedInner = [],selectedMain = [],pushModule = null"
    ></find-any-one-select-connection>

    <!-- search tips modal -->
    <find-any-one-search-tips-modal
      :popupTitle="LabelConstant.popupTitleLabelSearchTips"
      :isPopup="searchTipsPopup"
      @searchTipsPopupIput="
        (newSearchTipsPopup) => {
          searchTipsPopup = newSearchTipsPopup;
        }
      "
    ></find-any-one-search-tips-modal>

    <!-- Export Contact options modal (after selecting contacts and clicking on Export button this will open) -->
    <find-any-one-export-options-modal
      :popupTitle="LabelConstant.popupTitleLabelDownloadContacts"
      :isPopup="openExportContactOptionsPopup"
      @openExportContactOptionsPopupInput="
        (newOpenExportContactOptionsPopup) => {
          openExportContactOptionsPopup = newOpenExportContactOptionsPopup;
        }
      "
      @exportContactTypeInput="
        (newExportContactType) => {
          exportContactType = newExportContactType;
        }
      "
      @clickForGetContactLimits="getContactLimits()"
      @contactpushTocrm="openExportContactOptionsPopup = false,connectionPopup = true,pushModule = 'contact'"
    ></find-any-one-export-options-modal>

    <!-- Download Excel contacts (After getting export contacts use can download CSV or EXCEL) -->
    <find-any-one-download-final
      :popupTitle="LabelConstant.popupTitleLabelDownloadContacts"
      :isPopup="downloadExcelContactPopup"
      :sheetOptions="downloadContacts"
      :excelFileName="'Contacts.xlsx'"
      :excelOptions="{ bookType: 'xlsx' }"
      :csvFileName="'Contacts.csv'"
      :csvOptions="{ bookType: 'csv' }"
      :whichOptionShow="exportContactType"
      @clickForFinish="downloadExcelContactPopup = false, exportContactType = null"
      @downloadPushTOCRM="downloadExcelContactPopup = false,connectionPopup = true,pushModule = 'contact'"
      :showPushtoCRM="true"
    ></find-any-one-download-final>

    <!-- Credit warning popup (If there are enough contact limit then this will open) -->
    <find-any-one-credit-warning-modal
      :popupTitle="LabelConstant.popupTitleLabelDownloadContacts"
      :isPopup="creditWarningPopup"
      :isShowCreditDeduction="isCreditDeductionEnable"
      :creditDeductionMessage="numberOfCreditMessage"
      :isCompanyDetailPopup="companyDetailPopup"
      :isSelectedMainLength="selectedMain.length > 0"
      :selectedContactId="selectedContactId"
      :exportContactType="exportContactType"
      :isSelectedInnerLength="selectedInner.length > 0"
      @getContactDetail="creditWarningPopup = false,numberOfCreditMessage=null,isCreditDeductionEnable=false,tagModuleRequest='contactdetails',addTagToContacts(tags = {contactSelectedTags:[],isExportContact:false})"
      @exportContacts="creditWarningPopup = false,numberOfCreditMessage=null,isCreditDeductionEnable=false,listContactSavedTags(),tagModuleRequest='export',addTagsPopup = true"
      @clickForFinish="creditWarningPopup = false,numberOfCreditMessage = null,isCreditDeductionEnable = false,selectedMain = [],exportContactType = null,selectedContactId = null,selectedInner = []"
    ></find-any-one-credit-warning-modal>

    <!-- Add Update tag popup -->
    <find-any-one-add-update-tag-modal
      ref="addUpdateTag"
      :isAddUpdateTagPopupActive="addUpdateTagPopup"
      @saveNewTag="saveUpdateTag"
      @closeAddUpdateTagPopup="closeAddUpdateTagPopup"
      :tagDetails="userDefinedTagDetails"
    ></find-any-one-add-update-tag-modal>

    <!-- Add  tag To contactPopup -->
    <find-any-one-add-tag-to-selected-modal
      ref="addTagToSelectedContact"
      :isAddTagsPopupActive="addTagsPopup"
      :userDefinedTagsOptions="selectTagsOptions"
      @closePopupAddTagToSelected="addTagsPopup = false"
      @addTagToContacts="addTagToContacts"
      @createNewTag="addUpdateTagPopup = true"
      :requestFromModule="tagModuleRequest"
      @updateTagToContacts="updateTagsContactDetails"
      @removeTagToContacts="removeTagsFromContactConfirmation"
    ></find-any-one-add-tag-to-selected-modal>

    <!-- remove tag -->
    <find-any-one-remove-tag-modal
      :isRemoveTagPopupActive ="removeTagPopup"
      @clickCloseRemoveTagPopup="removeTagPopup = false,companyDetailPopup?selectedInner=[]:selectedMain=[]"
      @removeTagsFromContact="removeTagsFromContact"
    ></find-any-one-remove-tag-modal>

   <!-- manage tag -->
    <find-any-one-manage-tag-modal
      :isManageTagsPopupActive="manageTagsPopup"
      :userDefinedTagsList="selectTagsOptions"
      @closeManageTagPopup="manageTagsPopup = false"
      @clickOnDeleteTag="deleteUserDefinedTag"
      @createNewTag="addUpdateTagPopup = true"
      @editUserDefinedTag="editUserDefinedTag"
    ></find-any-one-manage-tag-modal>

    <!-- Contacts details modal -->
    <find-any-one-contact-details-modal
      :contactsDetailPopup="contactsDetailPopup"
      @clickForClose="closeContactDetailsPopup()"
      :contactDetails="contactDetails"
      @editTagsForContact="editTagsForContact()"
      moduleName="searchCompany"
    ></find-any-one-contact-details-modal>

    <!-- credit limit warning popup (If there are no enough contact limit then this will open) -->
    <find-any-one-credit-limit-warning-modal
      :popupTitle="LabelConstant.popupTitleLabelDownloadContacts"
      :isPopup="creditLimitWarningPopup"
      @clickForFinish="creditLimitWarningPopup = false"
    ></find-any-one-credit-limit-warning-modal>

    <!-- Select contact warning for add to my saved -->
    <find-any-one-select-contact-add-to-saved-warning-modal
      :popupTitle="LabelConstant.popupTitleLabelAddContacts"
      :isPopup="selecteContactWarningForAddToMySavedPopup"
      :selectContactWarningMessage="selecteContactWarningForAddToMySavedPopupMessage"
      @clickForFinish="selecteContactWarningForAddToMySavedPopup = false"
    ></find-any-one-select-contact-add-to-saved-warning-modal>

    <!-- Credit warning for add to my saved -->
    <find-any-one-credit-warning-add-to-saved-modal
      :popupTitle="LabelConstant.popupTitleLabelAddContacts"
      :isPopup="openAddToMySavedContactsCreditPopup"
      :creditWarningMessage="openAddToMySavedContactsCreditPopupMessage"
      @clickForYes="getContactLimitsForAddToMySavedContacts()"
      @clickForNo="cancelAddToMySavedContacts()"
    ></find-any-one-credit-warning-add-to-saved-modal>

    <!-- Success massage for add to my saved -->
    <find-any-one-success-message-add-to-saved-modal
      :popupTitle="LabelConstant.popupTitleLabelAddContacts"
      :isPopup="addToMySavedContactsSuccessPopup"
      @clickForFinish="addToMySavedContactsSuccessPopup = false"
    ></find-any-one-success-message-add-to-saved-modal>

    <!-- Company details popup -->
    <find-any-one-company-details-modal
      ref="searchContactsRef"
      :companyDetailPopup="companyDetailPopup"
      @clickForClose="closeCompanyDetailPopup()"
      :companyDetail="companyDetail"
      :showEntriesOptions="showEntriesOptions"
      :recordPerInnerPageValue="recordPerInnerPage"
      @recordPerInnerPageInput="
        (newRecordPerInnerPage) => {
          recordPerInnerPage = newRecordPerInnerPage;
        }
      "
      @clickForExport="getContactLimits()"
      @exportType="(newExportType) => {
        exportContactType = newExportType;
      }"
      :isShowAddToMySavedContactsButton="true"
      @clickForAddToMySavedContacts="addToMySavedContacts()"
      :selectedInnerValue="selectedInner"
      @selectedInnerInput="
        (newSelectedInner) => {
          selectedInner = newSelectedInner;
        }
      "
      @contactsDetailPopupInput="
        (newContactsDetailPopup) => {
          contactsDetailPopup = newContactsDetailPopup;
          tagModuleRequest = 'update';
        }
      "
      @selectedContactIdInput="
        (newSelectedContactId) => {
          selectedContactId = newSelectedContactId;
        }
      "
      @clickForGetSavedContactDetail="getSavedContactDetail()"
      @clickForOpenContactCreditPopup="openContactCreditPopup()"
      :companyTotalContacts="companyTotalContacts"
      :companyTotalPages="companyTotalPages"
      @clickForGetCompanyDetailByPagination="getCompanyDetailByPagination"
      :jobLevelOptions="jobLevelOptions"
      :jobFunctionOptions="jobFunctionOptions"
      :jobStartDateOptions="jobStartDateOptions"
      :contactRegionOptions="contactRegionOptions"
      :addressTypeOptions="addressTypeOptions"
      :contactCountriesOptions="contactCountriesOptions"
      :contactStatesOptions="contactStatesOptions"
      :personalContactGenderOptions="personalContactGenderOptions"
      :selectedCompanyId="selectedCompanyId"
      @addTagToSelected="addTagToSeleted(),tagModuleRequest = 'add'"
      @removeTagToSelected="removeTagSelected(),tagModuleRequest='remove'"
      @manageTags="manageTagsPopup = true,tagModuleRequest='add', listContactSavedTags()"
    ></find-any-one-company-details-modal>

    <!-- Select companies warning for export companies -->
    <find-any-one-select-contact-add-to-saved-warning-modal
      :popupTitle="LabelConstant.popupTitleLabelDownloadCompanies"
      :isPopup="selecteCompaniesWarningForExportCompaniesPopup"
      :selectContactWarningMessage="LabelConstant.infoLabelPleaseSelectCompaniesToExport"
      @clickForFinish="selecteCompaniesWarningForExportCompaniesPopup = false"
    ></find-any-one-select-contact-add-to-saved-warning-modal>

    <!-- Download Excel Companies -->
    <find-any-one-download-final
      :popupTitle="LabelConstant.popupTitleLabelDownloadCompanies"
      :isPopup="downloadExcelCompaniesPopup"
      :sheetOptions="downloadCompanies"
      :excelFileName="'Companies.xlsx'"
      :excelOptions="{ bookType: 'xlsx' }"
      :csvFileName="'Companies.csv'"
      :csvOptions="{ bookType: 'csv' }"
      :whichOptionShow="true"
      @clickForFinish="downloadExcelCompaniesPopup = false, selectedMain = []"
      @downloadPushTOCRM="downloadExcelCompaniesPopup = false,connectionPopup = true,pushModule = 'companies'"
      :showPushtoCRM="true"
    ></find-any-one-download-final>

    <!-- <vs-popup
      title="Download Companies"
      :active.sync="downloadExcelCompaniesPopup"
    >
      <template>
        <div class="vx-row px-10 mb-3">
          <div class="vx-col w-full text-center mb-2">
            <xlsx-workbook>
              <xlsx-sheet
                :collection="downloadCompany.data"
                v-for="downloadCompany in downloadCompanies"
                :key="downloadCompany.name"
                :sheet-name="downloadCompany.name"
              />
              <xlsx-download
                :filename="'Companies.xlsx'"
                :options="{ bookType: 'xlsx' }"
              >
                <button
                  class="link-text"
                  @click="downloadExcelCompaniesPopup = false"
                >
                  Download Excel
                </button>
              </xlsx-download>
              <br />
              <xlsx-download
                :filename="'Companies.csv'"
                :options="{ bookType: 'csv' }"
              >
                <button
                  class="link-text"
                  @click="downloadExcelCompaniesPopup = false"
                >
                  Download CSV
                </button>
              </xlsx-download>
            </xlsx-workbook>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          type="border"
          class="grey-btn"
          @click="(downloadExcelCompaniesPopup = false), (selectedMain = [])"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup> -->
    <vs-popup title="Coming soon" :active.sync="CommingsoonPopup">
      <template>
        <vx-card>
          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <div class="coming-soon sm:w-1/2 w-full py-5 mx-auto">
                <img
                  src="../../../src/assets/images/maintance.png"
                  class="d-inline-block w-full"
                />
                <h4 class="text-primary py-5">Coming soon</h4>
              </div>
            </div>
          </div>
        </vx-card>
        <div class="text-center float-right mt-6 mb-8">
          <vs-button
            color="primary"
            type="border"
            @click="CommingsoonPopup = false"
            >{{ LabelConstant.buttonLabelOkay }}</vs-button
          >
        </div>
      </template>
    </vs-popup>

    <!-- Download Excel -->
    <div v-show="false">
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadContact.data"
          v-for="downloadContact in downloadContacts"
          :key="downloadContact.name"
          :sheet-name="downloadContact.name"
        />
        <xlsx-download :filename="'Contacts.csv'" :options="{ bookType: 'csv' }">
          <a ref="selectedToCsv">{{ LabelConstant.buttonLabelExportCSV }}</a>
        </xlsx-download>
      </xlsx-workbook>
    </div>

    <!-- Download Excel -->
    <div v-show="false">
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadContact.data"
          v-for="downloadContact in downloadContacts"
          :key="downloadContact.name"
          :sheet-name="downloadContact.name"
        />
        <xlsx-download :filename="'Contacts.xlsx'" :options="{ bookType: 'xlsx' }">
          <a ref="selectedToExcel">{{ LabelConstant.buttonLabelExportExcel }}</a>
        </xlsx-download>
      </xlsx-workbook>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import InputTag from "vue-input-tag";
import vSelect from "vue-select";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);
import FindAnyOneSearchTipsModal from "../../components/modal/FindAnyOneSearchTipsModal.vue";
import FindAnyOneExportOptionsModal from "../../components/modal/FindAnyOneExportOptionsModal.vue";
import FindAnyOneDownloadFinal from "../../components/modal/FindAnyOneDownloadFinal.vue";
import FindAnyOneCreditWarningModal from "../../components/modal/FindAnyOneCreditWarningModal.vue";
import FindAnyOneContactDetailsModal from "../../components/modal/FindAnyOneContactDetailsModal.vue";
import FindAnyOneCompanyDetailsModal from "../../components/modal/FindAnyOneCompanyDetailsModal.vue";
import FindAnyOneCreditLimitWarningModal from "../../components/modal/FindAnyOneCreditLimitWarningModal.vue";
import FindAnyOneSelectContactAddToSavedWarningModal from "../../components/modal/FindAnyOneSelectContactAddToSavedWarningModal.vue";
import FindAnyOneCreditWarningAddToSavedModal from "../../components/modal/FindAnyOneCreditWarningAddToSavedModal.vue";
import FindAnyOneSuccessMessageAddToSavedModal from "../../components/modal/FindAnyOneSuccessMessageAddToSavedModal.vue";
import FindAnyOneSelectConnection from "../../components/modal/FindAnyOneSelectConnection.vue";
import FindAnyOneAddTagToSelectedModal from "../../components/modal/FindAnyOneAddTagToSelectedModal.vue";
import FindAnyOneManageTagModal from "../../components/modal/FindAnyOneManageTagModal.vue";
import FindAnyOneAddUpdateTagModal from "../../components/modal/FindAnyOneAddUpdateTagModal.vue";
import FindAnyOneRemoveTagModal from "../../components/modal/FIndAnyOneRemoveTagModal.vue"
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { BPagination } from 'bootstrap-vue'
import VueJwtDecode from "vue-jwt-decode";
import DropdownMenu from "v-dropdown-menu";
import "v-dropdown-menu/dist/v-dropdown-menu.css";
export default {
  components: {
    InputTag,
    "v-select": vSelect,
    FindAnyOneSearchTipsModal,
    FindAnyOneExportOptionsModal,
    FindAnyOneDownloadFinal,
    FindAnyOneCreditWarningModal,
    FindAnyOneContactDetailsModal,
    FindAnyOneCompanyDetailsModal,
    FindAnyOneCreditLimitWarningModal,
    FindAnyOneSelectContactAddToSavedWarningModal,
    FindAnyOneCreditWarningAddToSavedModal,
    FindAnyOneSuccessMessageAddToSavedModal,
    FindAnyOneSelectConnection,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    BPagination,
    VueJwtDecode,
    FindAnyOneAddTagToSelectedModal,
    FindAnyOneManageTagModal,
    FindAnyOneAddUpdateTagModal,
    FindAnyOneRemoveTagModal,
    DropdownMenu
  },
  data: () => ({
    chromeExtensionLink:'https://chrome.google.com/webstore/detail/visual-visitor-easily-fin/lgaajoacmibdfdjklimiamkbjaafekhb',
    appRunningOnDev:process.env.VUE_APP_RUNNING_ON_DEV?process.env.VUE_APP_RUNNING_ON_DEV:'',
    initialRole:'',
    selectedCompanyId: null,
    showEntriesOptions: [
      { key: 10, value: 10 },
      { key: 25, value: 25 },
      { key: 50, value: 50 },
      { key: 100, value: 100 },
      { key: 500, value: 500 },
      { key: 1000, value: 1000 },
    ],

    //Job Criteria
    jobLevelOptions: null,
    jobFunctionOptions: null,
    jobStartDateOptions: null,

    //Location Criteria
    contactRegionOptions: null,

    //personal Criteria
    personalContactGenderOptions: null,

    showCompanyNameText: false,
    companyName: [],

    showCompanyWebAddressText: false,
    companyWebAddress: [],

    showCompanyIndustryOptions: false,
    companyIndustry: [],
    companyIndustryOptions: null,

    showCompanyEmployeeSizeOptions: false,
    companyEmployeeSize: [],
    companyEmployeeSizeOptions: null,

    showCompanyRevenueBandsOptions: false,
    companyRevenueBands: [],
    companyRevenueBandsOptions: null,

    showCompanyNAICSOptions: false,
    companyNAICS: [],
    companyNAICSOptions: null,

    showCompanySICSOptions: false,
    companySICS: [],
    companySICSOptions: null,

    // showContactRegionOptions: false,
    // contactRegion: []
    // contactRegionOptions: null,

    showLocationTypeOptions: false,
    locationType: [],
    locationTypeOptions: null,

    showContactCountriesOptions: false,
    contactCountries: [],
    contactCountriesOptions: null,

    showContactSatesOptions: false,
    contactStates: [],
    contactStatesOptions: null,

    showContactCity: false,
    contactCity: [],

    showContactZipCode: false,
    contactZipCode: [],

    tagsTempArr: [],

    searchCompaniesParams: null,
    currentPage:1,
    totalCompanies: 0,
    totalPages: 0,
    recordPerMainPage: 10,

    searchTipsPopup: false,
    companies: [],
    selectedMain: [],

    companyDetailPopup: false,
    selectedInner: [],
    companyDetail: null,
    companySearchContactsParams: null,
    companyTotalContacts: 0,
    companyTotalPages: 0,
    recordPerInnerPage: 10,

    downloadExcelCompaniesPopup: false,
    downloadCompanies: null,

    creditWarningPopup: false,
    contactsDetailPopup: false,
    numberOfCreditMessage: null,
    selectedContactId: null,
    contactDetails: null,
    isCreditDeductionEnable: false,

    downloadExcelContactPopup: false,
    downloadContacts: null,
    creditLimitWarningPopup: false,
    exportContactType: null,
    openExportContactOptionsPopup: false,

    isContactDetailsPopupForSaved: false,

    selecteContactWarningForAddToMySavedPopup: false,
    selecteContactWarningForAddToMySavedPopupMessage: null,

    addabelContactsCount: 0,
    openAddToMySavedContactsCreditPopup: false,
    openAddToMySavedContactsCreditPopupMessage: null,

    addToMySavedContactsSuccessPopup: false,

    selecteCompaniesWarningForExportCompaniesPopup: false,

    addressTypeOptions: [],

    connectionPopup: false,
    pushModule: null,
    CommingsoonPopup: false,

    addTagsPopup:false,
    addUpdateTagPopup:false,
    manageTagsPopup:false,
    removeTagPopup:false,
    selectTagsOptions:[],
    userDefinedTagDetails:null,
    tagModuleRequest:null,
    tagRemoveList:null,
  }),
  computed: {
    tags: {
      get: function () {
        let arr = [];
        let that = this;
        //Company Criteria
        for (let i = 0; i < this.companyName.length; i++) {
          if (!arr.includes(this.companyName[i])) {
            arr.push("Name:" + this.companyName[i]);
          }
        }
        for (let i = 0; i < this.companyWebAddress.length; i++) {
          if (!arr.includes(this.companyWebAddress[i])) {
            arr.push("Website:" + this.companyWebAddress[i]);
          }
        }
        for (let i = 0; i < this.companyIndustry.length; i++) {
          if (!arr.includes(this.companyIndustry[i])) {
            arr.push("Industry:" + this.companyIndustry[i].Value);
          }
        }
        for (let i = 0; i < this.companyEmployeeSize.length; i++) {
          if (!arr.includes(this.companyEmployeeSize[i])) {
            arr.push("Employee Size:" + this.companyEmployeeSize[i].Value);
          }
        }
        for (let i = 0; i < this.companyRevenueBands.length; i++) {
          if (!arr.includes(this.companyRevenueBands[i])) {
            arr.push("Revenue Band:" + this.companyRevenueBands[i].Value);
          }
        }
        for (let i = 0; i < this.companyNAICS.length; i++) {
          if (!arr.includes(this.companyNAICS[i])) {
            arr.push("NAICS Code:" + this.companyNAICS[i].Value);
          }
        }
        for (let i = 0; i < this.companySICS.length; i++) {
          if (!arr.includes(this.companySICS[i])) {
            arr.push("SIC Code:" + this.companySICS[i].Value);
          }
        }
        //Location Criteria
        // for (let i = 0; i < this.contactRegion.length; i++) {
        //   if (!arr.includes(this.contactRegion[i])) {
        //     arr.push('Contact Region:' + this.contactRegion[i].Value);
        //   }
        // }
        for (let i = 0; i < this.locationType.length; i++) {
          if (!arr.includes(this.locationType[i])) {
            arr.push("Location Type:" + this.locationType[i].Value);
          }
        }
        for (let i = 0; i < this.contactCountries.length; i++) {
          if (!arr.includes(this.contactCountries[i])) {
            arr.push("Country:" + this.contactCountries[i].Value);
          }
        }
        for (let i = 0; i < this.contactStates.length; i++) {
          if (!arr.includes(this.contactStates[i])) {
            arr.push("State:" + this.contactStates[i].Value);
          }
        }
        for (let i = 0; i < this.contactCity.length; i++) {
          if (!arr.includes(this.contactCity[i])) {
            arr.push("City:" + this.contactCity[i]);
          }
        }
        for (let i = 0; i < this.contactZipCode.length; i++) {
          if (!arr.includes(this.contactZipCode[i])) {
            arr.push("Zip:" + this.contactZipCode[i]);
          }
        }
        that.tagsTempArr = [];
        that.tagsTempArr = arr;
        return arr;
      },
      set: function () {},
    },
  },
  created() {
    this.decodeJWT();
    this.listJobLevels();
    this.listJobFunctions();
    this.listJobStartDates();
    this.listCompanyIndustries();
    this.listCompanyEmployeeSizes();
    this.listCompanyRevenueBands();
    this.listCompanyNAICS();
    this.listCompanySICS();
    this.listContactRegions();
    this.listAddressTypes();
    this.listCompanyLocationType();
    this.listContactCountries();
    this.listContactStates();
    this.listContactGenders();
  },
  methods: {
    // comma and semicolon separated input tag
    termSeparation(values,name){
      if(values[values.length-1]){
        let singleValue=values[values.length-1]
        let splitValue=singleValue.split(/[,|;]/);
        let valueSeprated=[]
        splitValue.forEach(e => {
          if(e.trim().length){
            valueSeprated.push(e.trim());
          }
        });

        if(name=="companyName"){
          this.companyName.pop();
          this.companyName=this.companyName.concat(valueSeprated);
          this.companyName=[...new Set(this.companyName)];
        }
        if(name=="companyWebAddress"){
          this.companyWebAddress.pop();
          this.companyWebAddress=this.companyWebAddress.concat(valueSeprated);
          this.companyWebAddress=[...new Set(this.companyWebAddress)];
        }
        if(name=="contactCity"){
          this.contactCity.pop();
          this.contactCity=this.contactCity.concat(valueSeprated);
          this.contactCity=[...new Set(this.contactCity)];
        }
        if(name=="contactZipCode"){
          this.contactZipCode.pop();
          this.contactZipCode=this.contactZipCode.concat(valueSeprated);
          this.contactZipCode=[...new Set(this.contactZipCode)];
        }
      }
    },
    clearSearchCriteria() {
      //Company Criteria
      this.showCompanyNameText = false;
      this.showCompanyWebAddressText = false;
      this.showCompanyIndustryOptions = false;
      this.showCompanyEmployeeSizeOptions = false;
      this.showCompanyRevenueBandsOptions = false;
      this.showCompanyNAICSOptions = false;
      this.showCompanySICSOptions = false;

      //Location Criteria
      // this.showContactRegionOptions = false;
      this.showLocationTypeOptions = false;
      this.showContactCountriesOptions = false;
      this.showContactSatesOptions = false;
      this.showContactCity = false;
      this.showContactZipCode = false;

      //Company Criteria
      this.companyName = [];
      this.companyWebAddress = [];
      this.companyIndustry = [];
      this.companyEmployeeSize = [];
      this.companyRevenueBands = [];
      this.companyNAICS = [];
      this.companySICS = [];

      //Location Criteria
      // this.contactRegion = []
      this.locationType = [];
      this.contactCountries = [];
      this.contactStates = [];
      this.contactCity = [];
      this.contactZipCode = [];

      this.companies = [];
      this.selectedMain = [];
      this.totalCompanies = 0;
    },
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    },
    testUpdateSelectedTags(value) {
      let keyArr = this.tagsTempArr.filter((x) => value.indexOf(x) === -1);
      let testStr = keyArr[0];
      let keyStr = keyArr[0].substring(keyArr[0].indexOf(":") + 1);
      //Company Criteria
      if (testStr.includes("Name:")) {
        this.companyName = this.arrayRemove(this.companyName, keyStr);
      }
      if (testStr.includes("Website:")) {
        this.companyWebAddress = this.arrayRemove(
          this.companyWebAddress,
          keyStr
        );
      }
      if (testStr.includes("Industry:")) {
        let removableIndex = this.companyIndustry.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.companyIndustry.splice(removableIndex, 1);
      }
      if (testStr.includes("Employee Size:")) {
        let removableIndex = this.companyEmployeeSize.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.companyEmployeeSize.splice(removableIndex, 1);
      }
      if (testStr.includes("Revenue Band:")) {
        let removableIndex = this.companyRevenueBands.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.companyRevenueBands.splice(removableIndex, 1);
      }
      if (testStr.includes("NAICS Code:")) {
        let removableIndex = this.companyNAICS.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.companyNAICS.splice(removableIndex, 1);
      }
      if (testStr.includes("SIC Code:")) {
        let removableIndex = this.companySICS.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.companySICS.splice(removableIndex, 1);
      }
      //Location Criteria
      // if (testStr.includes("Region:")) {
      //   let removableIndex = this.contactRegion.findIndex(function (
      //     arr
      //   ) {
      //     if (arr.Value == keyStr) return true;
      //   });
      //   this.contactRegion.splice(removableIndex, 1);
      // }
      if (testStr.includes("Location Type:")) {
        let removableIndex = this.locationType.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.locationType.splice(removableIndex, 1);
      }
      if (testStr.includes("Country:")) {
        let removableIndex = this.contactCountries.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.contactCountries.splice(removableIndex, 1);
      }
      if (testStr.includes("State:")) {
        let removableIndex = this.contactStates.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.contactStates.splice(removableIndex, 1);
      }
      if (testStr.includes("City:")) {
        this.contactCity = this.arrayRemove(this.contactCity, keyStr);
      }
      if (testStr.includes("Zip:")) {
        this.contactZipCode = this.arrayRemove(this.contactZipCode, keyStr);
      }
    },
    listJobLevels() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListJobLevels")
        .then((response) => {
          let data = response.data;
          this.jobLevelOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listJobFunctions() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListJobFunctions")
        .then((response) => {
          let data = response.data;
          (this.jobFunctionOptions = data), this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listJobStartDates() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListJobStartDates")
        .then((response) => {
          let data = response.data;
          this.jobStartDateOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyIndustries() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyIndustries")
        .then((response) => {
          let data = response.data;
          this.companyIndustryOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyEmployeeSizes() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyEmployeeSizes")
        .then((response) => {
          let data = response.data;
          this.companyEmployeeSizeOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyRevenueBands() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyRevenueBands")
        .then((response) => {
          let data = response.data;
          this.companyRevenueBandsOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyNAICS() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyNAICS")
        .then((response) => {
          let data = response.data;
          this.companyNAICSOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanySICS() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanySICS")
        .then((response) => {
          let data = response.data;
          this.companySICSOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listCompanyLocationType() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListCompanyLocationTypes")
        .then((response) => {
          let data = response.data;
          this.locationTypeOptions = data;
          this.locationType.push(this.locationTypeOptions[0]);
          this.showLocationTypeOptions = true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactRegions() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactRegions")
        .then((response) => {
          let data = response.data;
          this.contactRegionOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listAddressTypes() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactAddressTypes")
        .then((response) => {
          let data = response.data;
          this.addressTypeOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactCountries() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactCountries")
        .then((response) => {
          let data = response.data;
          this.contactCountriesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactStates() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactStates")
        .then((response) => {
          let data = response.data;
          this.contactStatesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    listContactGenders() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/ListContactGenders")
        .then((response) => {
          let data = response.data;
          this.personalContactGenderOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    searchCompanies() {
      //Company Criteria
      let isCompanyName = false;
      let companyNameParam = [];
      if (this.companyName.length > 0) {
        isCompanyName = true;
        for (let i = 0; i < this.companyName.length; i++) {
          companyNameParam.push({
            Text: this.companyName[i],
          });
        }
      }
      let isCompanyWebAddress = false;
      let companyWebAddressParam = [];
      if (this.companyWebAddress.length > 0) {
        isCompanyWebAddress = true;
        for (let i = 0; i < this.companyWebAddress.length; i++) {
          companyWebAddressParam.push({
            Text: this.companyWebAddress[i],
          });
        }
      }
      let isCompanyIndustry = false;
      let companyIndustryParam = [];
      if (this.companyIndustry.length > 0) {
        isCompanyIndustry = true;
        for (let i = 0; i < this.companyIndustry.length; i++) {
          companyIndustryParam.push({
            Text: this.companyIndustry[i].Key,
          });
        }
      }
      let isCompanyEmployeeSize = false;
      let companyEmployeeSizeParam = [];
      if (this.companyEmployeeSize.length > 0) {
        isCompanyEmployeeSize = true;
        for (let i = 0; i < this.companyEmployeeSize.length; i++) {
          companyEmployeeSizeParam.push({
            Text: this.companyEmployeeSize[i].Key,
          });
        }
      }
      let isCompanyRevenueBands = false;
      let companyRevenueBandsParam = [];
      if (this.companyRevenueBands.length > 0) {
        isCompanyRevenueBands = true;
        for (let i = 0; i < this.companyRevenueBands.length; i++) {
          companyRevenueBandsParam.push({
            Text: this.companyRevenueBands[i].Key,
          });
        }
      }
      let isCompanyNAICS = false;
      let companyNAICSParam = [];
      if (this.companyNAICS.length > 0) {
        isCompanyNAICS = true;
        for (let i = 0; i < this.companyNAICS.length; i++) {
          companyNAICSParam.push({
            Text: this.companyNAICS[i].Key,
          });
        }
      }
      let isCompanySICS = false;
      let companySICSParam = [];
      if (this.companySICS.length > 0) {
        isCompanySICS = true;
        for (let i = 0; i < this.companySICS.length; i++) {
          companySICSParam.push({
            Text: this.companySICS[i].Key,
          });
        }
      }
      //Location Criteria
      // let isContactRegion = false;
      // let contactRegionParam = [];
      // if (this.contactRegion.length > 0) {
      //   isContactRegion = true;
      //   for (let i = 0; i < this.contactRegion.length; i++) {
      //     contactRegionParam.push({
      //       Text: this.contactRegion[i].Key,
      //     });
      //   }
      // }
      let islocationType = false;
      let locationTypeParam = [];
      if (this.locationType.length > 0) {
        islocationType = true;
        for (let i = 0; i < this.locationType.length; i++) {
          locationTypeParam.push({
            Text: this.locationType[i].Key,
          });
        }
      }
      let isContactCountries = false;
      let contactCountriesParam = [];
      if (this.contactCountries.length > 0) {
        isContactCountries = true;
        for (let i = 0; i < this.contactCountries.length; i++) {
          contactCountriesParam.push({
            Text: this.contactCountries[i].Key,
          });
        }
      }
      let isContactStates = false;
      let contactStatesParam = [];
      if (this.contactStates.length > 0) {
        isContactStates = true;
        for (let i = 0; i < this.contactStates.length; i++) {
          contactStatesParam.push({
            Text: this.contactStates[i].Key,
          });
        }
      }
      let isContactCity = false;
      let contactCityParam = [];
      if (this.contactCity.length > 0) {
        isContactCity = true;
        for (let i = 0; i < this.contactCity.length; i++) {
          contactCityParam.push({
            Text: this.contactCity[i],
          });
        }
      }
      let isContactZipCode = false;
      let contactZipCodeParam = [];
      if (this.contactZipCode.length > 0) {
        isContactZipCode = true;
        for (let i = 0; i < this.contactZipCode.length; i++) {
          contactZipCodeParam.push({
            Text: this.contactZipCode[i],
          });
        }
      }

      this.searchCompaniesParams = {
        IsCompanyNamesSelected: isCompanyName,
        CompanyNames: companyNameParam,
        IsURLsSelected: isCompanyWebAddress,
        Urls: companyWebAddressParam,
        IsCompanyIndustriesSelected: isCompanyIndustry,
        CompanyIndustries: companyIndustryParam,
        IsCompanySICCodesSelected: isCompanySICS,
        CompanySICCodes: companySICSParam,
        IsCompanyNAICSCodesSelected: isCompanyNAICS,
        CompanyNAICSCodes: companyNAICSParam,
        IsCompanySizesSelected: isCompanyEmployeeSize,
        CompanySizeIds: companyEmployeeSizeParam,
        IsCompanyRevenuesSelected: isCompanyRevenueBands,
        CompanyRevenueIds: companyRevenueBandsParam,

        IsZipCodesSelected: isContactZipCode,
        ZipCodes: contactZipCodeParam,
        IsCitiesSelected: isContactCity,
        Cities: contactCityParam,
        IsStatesSelected: isContactStates,
        States: contactStatesParam,
        IsCountrySelected: isContactCountries,
        Countries: contactCountriesParam,
        Page: 1,
        PageSize: this.recordPerMainPage,
        // IsRegionSelected: isContactRegion,
        // Regions: contactRegionParam,
        IsLocationTypesSelected: islocationType,
        LocationTypes: locationTypeParam,
      };
      if (this.companies.length > 0) {
        this.currentPage =1;
      }
      this.$vs.loading();
      this.axios
        .post(
          "/ws/FindAnyoneSearchCompany/SearchCompanies",
          this.searchCompaniesParams
        )
        .then((response) => {
          let data = response.data;
          if (data.Companies) {
            this.companies = data.Companies;
          } else {
            this.companies = [];
          }
          this.totalCompanies = data.CompanyCount;
          this.totalPages = Math.ceil(
            data.CompanyCount / data.CompaniesPerPage
          );
          if (data.CompanyCount >= 10000) {
            this.totalPages = 999;
          }
          let that = this;
          this.companies.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress2(manipulatedData);
            return manipulatedData;
          });
          this.selectedMain = [];
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    searchCompaniesByPagination() {
      this.searchCompaniesParams.Page = this.currentPage;
      this.searchCompaniesParams.PageSize = this.recordPerMainPage;
      // this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneSearchCompany/SearchCompanies",this.searchCompaniesParams)
        .then((response) => {
          let data = response.data;
          if (data.Companies) {
            this.companies = data.Companies;
          } else {
            this.companies = [];
          }
          this.totalCompanies = data.CompanyCount;
          this.totalPages = Math.ceil(data.CompanyCount / data.CompaniesPerPage);
          if (data.CompanyCount >= 10000) {
            this.totalPages = 999;
          }
          let that = this;
          this.companies.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress2(manipulatedData);
            return manipulatedData;
          });
          this.selectedMain = [];
          // this.$vs.loading.close();
        })
        .catch((e) => {
          // this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Get company details
    getCompanyDetail(id) {
      this.companySearchContactsParams = {
        CompanyId: id,
        Page: 1,
        PageSize: this.recordPerInnerPage,
      };
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneSearchCompany/GetCompanyDetail",this.companySearchContactsParams)
        .then((response) => {
          let data = response.data;
          this.companyDetail = data;
          this.companyTotalContacts = data.ContactSummary.ContactCount;
          this.companyTotalPages = Math.ceil(data.ContactSummary.ContactCount / data.ContactSummary.ContactsPerPage);
          if (data.ContactSummary.ContactCount >= 10000) {
            this.companyTotalPages = 999;
          }
          let companyAddressArr = [];
          companyAddressArr.push(this.companyDetail.CompanyAddressLine1);
          companyAddressArr.push(this.companyDetail.CompanyAddressLine2);
          companyAddressArr.push(this.companyDetail.CompanyCity);
          companyAddressArr.push(this.companyDetail.CompanyRegion);
          companyAddressArr.push(this.companyDetail.CompanyCountry);
          let companyAddressFinal = "";
          for (let i = 0; i < companyAddressArr.length; i++) {
            if (companyAddressArr[i] && companyAddressArr[i].length > 0) {
              companyAddressFinal += companyAddressArr[i] + ", ";
            }
          }

          this.companyDetail.companyAddress = companyAddressFinal.substring(0,companyAddressFinal.length - 2);
          let that = this;
          this.companyDetail.ContactSummary.Contacts.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress(manipulatedData);
            manipulatedData.fullName = manipulatedData.ContactFirstName + " " + manipulatedData.ContactLastName;
            return manipulatedData;
          });
          this.$refs.searchContactsRef.searchContacts();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Get company details to paginate the contacts
    getCompanyDetailByPagination(pageNumber) {
      this.companySearchContactsParams.Page = pageNumber;
      this.companySearchContactsParams.PageSize = this.recordPerInnerPage;
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneSearchCompany/GetCompanyDetail",this.companySearchContactsParams)
        .then((response) => {
          let data = response.data;
          this.companyDetail = data;
          this.companyTotalContacts = data.ContactSummary.ContactCount;
          this.companyTotalPages = Math.ceil(data.ContactSummary.ContactCount / data.ContactSummary.ContactsPerPage);
          if (data.ContactSummary.ContactCount >= 10000) {
            this.companyTotalPages = 999;
          }
          let that = this;
          this.companyDetail.ContactSummary.Contacts.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress(manipulatedData);
            manipulatedData.fullName = manipulatedData.ContactFirstName + " " + manipulatedData.ContactLastName;
            return manipulatedData;
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //close company details popup
    closeCompanyDetailPopup() {
      this.selectedMain = [];
      this.selectedInner = [];
      this.companyDetailPopup = false;
      this.companyDetail = null;
      this.companySearchContactsParams = null;
      this.companyTotalContacts = 0;
      this.companyTotalPages = 0;
    },
    //Get download contacts limit to check when exporting
    getContactLimits() {
      if(this.selectedInner.length > 0){
        this.$vs.loading();
        this.axios
          .get("/ws/FindAnyoneSearchContacts/GetContactLimits")
          .then((response) => {
            let data = response.data;
            let selectedContacts = [];
            if (this.companyDetailPopup) {
              selectedContacts = this.selectedInner;
            } else {
              selectedContacts = this.selectedMain;
            }
            if (data.DailyContactLimit == -1) {
              //this.exportContacts(selectedContacts);
              this.checkCreditDeductForExport(selectedContacts);
            } else if (
              selectedContacts.length <= data.TotalContactLimitRemaining &&
              selectedContacts.length <= data.DailyContactLimitRemaining
            ) {
              this.openContactCreditPopup();
            } else {
              this.creditLimitWarningPopup = true;
            }
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
            this.showError(e);
          });
      } else {
        this.selecteContactWarningForAddToMySavedPopupMessage ="Please select contacts to export contact"
        this.selecteContactWarningForAddToMySavedPopup = true;
      }
    },
    //close contact details popup
    closeContactDetailsPopup() {
      this.contactsDetailPopup = false;
      this.selectedContactId = null;
      this.contactDetails = null;
      // if (!this.isContactDetailsPopupForSaved) {
      //   if (this.companyDetailPopup) {
      //     this.$refs.searchContactsRef.searchPaginationSetTimeOut();
      //   }
      // }
      this.selectedInner = [];
      this.isContactDetailsPopupForSaved = false;
    },
    //Open credit deduction message popup
    openContactCreditPopup() {
      let selectedContacts = [];
      if (this.selectedContactId && !this.exportContactType) {
        this.numberOfCreditMessage = "This will deduct 1 credit";
        this.isCreditDeductionEnable = true;
      } else {
        if (this.companyDetailPopup) {
          selectedContacts = this.selectedInner;
        } else {
          selectedContacts = this.selectedMain;
        }
        if (selectedContacts.length > 0) {
          this.isCreditDeductionEnable = true;
          this.numberOfCreditMessage ="This will deduct " + selectedContacts.length + " credits";
        } else {
          this.isCreditDeductionEnable = false;
        }
      }
      this.creditWarningPopup = true;
    },
    //Get contacts for download as CSV or EXCEL
    exportContacts(selectedContacts) {
      let exportContactsParams = [];
      for (let i = 0; i < selectedContacts.length; i++) {
        exportContactsParams.push(selectedContacts[i].Id);
      }
      this.creditWarningPopup = false;
      this.numberOfCreditMessage = null;
      this.isCreditDeductionEnable = false;
      this.selectedMain = [];
      this.selectedInner = [];
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneSearchContacts/ExportContacts", {contactIds: exportContactsParams})
        .then((response) => {
          let data = response.data;
          //this.downloadExcelContactPopup = true;
          setTimeout(() => {
            if(this.exportContactType == 'csv'){
                this.$refs.selectedToCsv.click();
              } else {
                this.$refs.selectedToExcel.click();
              }
            }, 500);
          this.downloadContacts = [{ name: "Contacts", data: data }];
          this.$refs.searchContactsRef.searchPaginationSetTimeOut();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Get non saved contact details with using credit
    getContactDetail() {
      this.creditWarningPopup = false;
      this.numberOfCreditMessage = null;
      this.isCreditDeductionEnable = false;
      this.contactsDetailPopup = true;
      this.tagModuleRequest = 'update';
      this.$vs.loading();
      this.axios
        .get(
          "/ws/FindAnyoneSearchContacts/GetContactDetail?id=" +
            this.selectedContactId
        )
        .then((response) => {
          let data = response.data;
          this.contactDetails = data;
          let officeAddressArr = [];
          let contactAddressArr = [];
          let physicalAddress = [];
          officeAddressArr.push(this.contactDetails.ContactOfficeAddress);
          officeAddressArr.push(this.contactDetails.ContactOfficeCity);
          officeAddressArr.push(this.contactDetails.ContactOfficeState);
          officeAddressArr.push(this.contactDetails.ContactOfficeCountry);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine1);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine2);
          contactAddressArr.push(this.contactDetails.CompanyCity);
          contactAddressArr.push(this.contactDetails.CompanyRegion);
          contactAddressArr.push(this.contactDetails.CompanyCountry);
          physicalAddress.push(this.contactDetails.ContactPhysicalCity);
          physicalAddress.push(this.contactDetails.ContactPhysicalState);
          physicalAddress.push(this.contactDetails.ContactPhysicalCountry);
          let officeAddressFinal = "";
          let contactAddressFinal = "";
          let physicalAddressFinal = "";
          for (let i = 0; i < officeAddressArr.length; i++) {
            if (officeAddressArr[i] && officeAddressArr[i].length > 0) {
              officeAddressFinal += officeAddressArr[i] + ", ";
            }
          }
          for (let i = 0; i < contactAddressArr.length; i++) {
            if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
              contactAddressFinal += contactAddressArr[i] + ", ";
            }
          }
          for (let i = 0; i < physicalAddress.length; i++) {
            if (physicalAddress[i] && physicalAddress[i].length > 0) {
              physicalAddressFinal += physicalAddress[i] + ", ";
            }
          }
          this.contactDetails.officeAddress = officeAddressFinal.substring(0,officeAddressFinal.length - 2);
          this.contactDetails.contactAddress = contactAddressFinal.substring(0,contactAddressFinal.length - 2);
          this.contactDetails.physicalAddress = physicalAddressFinal.substring(0,physicalAddressFinal.length - 2);
          this.listContactSavedTags();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //Get saved contact details with using credit
    getSavedContactDetail() {
      this.creditWarningPopup = false;
      this.numberOfCreditMessage = null;
      this.isCreditDeductionEnable = false;
      this.contactsDetailPopup = true;
      this.tagModuleRequest = 'update';

      this.isContactDetailsPopupForSaved = true;
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSavedContacts/GetSavedContactDetail?id=" +this.selectedContactId)
        .then((response) => {
          let data = response.data;
          this.contactDetails = data;
          let officeAddressArr = [];
          let contactAddressArr = [];
          let physicalAddress = [];
          officeAddressArr.push(this.contactDetails.ContactOfficeAddress);
          officeAddressArr.push(this.contactDetails.ContactOfficeCity);
          officeAddressArr.push(this.contactDetails.ContactOfficeState);
          officeAddressArr.push(this.contactDetails.ContactOfficeCountry);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine1);
          contactAddressArr.push(this.contactDetails.CompanyAddressLine2);
          contactAddressArr.push(this.contactDetails.CompanyCity);
          contactAddressArr.push(this.contactDetails.CompanyRegion);
          contactAddressArr.push(this.contactDetails.CompanyCountry);
          physicalAddress.push(this.contactDetails.ContactPhysicalCity);
          physicalAddress.push(this.contactDetails.ContactPhysicalState);
          physicalAddress.push(this.contactDetails.ContactPhysicalCountry);
          let officeAddressFinal = "";
          let contactAddressFinal = "";
          let physicalAddressFinal = "";
          for (let i = 0; i < officeAddressArr.length; i++) {
            if (officeAddressArr[i] && officeAddressArr[i].length > 0) {
              officeAddressFinal += officeAddressArr[i] + ", ";
            }
          }
          for (let i = 0; i < contactAddressArr.length; i++) {
            if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
              contactAddressFinal += contactAddressArr[i] + ", ";
            }
          }
          for (let i = 0; i < physicalAddress.length; i++) {
            if (physicalAddress[i] && physicalAddress[i].length > 0) {
              physicalAddressFinal += physicalAddress[i] + ", ";
            }
          }
          this.contactDetails.officeAddress = officeAddressFinal.substring(0,officeAddressFinal.length - 2);
          this.contactDetails.contactAddress = contactAddressFinal.substring(0,contactAddressFinal.length - 2);
          this.contactDetails.physicalAddress = physicalAddressFinal.substring(0,physicalAddressFinal.length - 2);
          this.listContactSavedTags();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    addToMySavedContacts() {
      if (this.selectedInner.length > 0) {
        for (let i = 0; i < this.selectedInner.length; i++) {
          if (!this.selectedInner[i].IsSavedContact) {
            this.addabelContactsCount++;
          }
        }
        if (this.addabelContactsCount > 0) {
          this.selecteContactWarningForAddToMySavedPopup = false;
          this.selecteContactWarningForAddToMySavedPopupMessage = null;
          this.openAddToMySavedContactsCreditPopup = true;
          if (this.addabelContactsCount == 1) {
            this.openAddToMySavedContactsCreditPopupMessage ="This will deduct 1 credit";
          } else {
            this.openAddToMySavedContactsCreditPopupMessage ="This will deduct " + this.addabelContactsCount + " credits";
          }
        } else {
          this.selecteContactWarningForAddToMySavedPopup = true;
          this.selecteContactWarningForAddToMySavedPopupMessage ="Please select not saved contacts to add as My Saved contacts.";
        }
      } else {
        this.selecteContactWarningForAddToMySavedPopup = true;
        this.selecteContactWarningForAddToMySavedPopupMessage ="Please select contacts to add.";
      }
    },
    cancelAddToMySavedContacts() {
      this.addabelContactsCount = 0;
      this.openAddToMySavedContactsCreditPopup = false;
      this.openAddToMySavedContactsCreditPopupMessage = null;
      this.selectedMain = [];
      this.selectedInner = [];
    },
    getContactLimitsForAddToMySavedContacts() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneSearchContacts/GetContactLimits")
        .then((response) => {
          let data = response.data;
          if (data.DailyContactLimit == -1) {
            //this.exportContactsForAddToMySavedContacts(this.selectedInner);
            this.addabelContactsCount = 0
            this.openAddToMySavedContactsCreditPopup = false
            this.openAddToMySavedContactsCreditPopupMessage = null
            this.listContactSavedTags();
            this.addTagsPopup = true;
            this.tagModuleRequest = 'savecontact';
          } else if (
            this.selectedInner.length <= data.TotalContactLimitRemaining &&
            this.selectedInner.length <= data.DailyContactLimitRemaining
          ) {
            this.addabelContactsCount = 0
            this.openAddToMySavedContactsCreditPopup = false
            this.openAddToMySavedContactsCreditPopupMessage = null
            this.listContactSavedTags();
            this.addTagsPopup = true;
            this.tagModuleRequest = 'savecontact';
            //this.exportContactsForAddToMySavedContacts(this.selectedInner);
          } else {
            this.creditLimitWarningPopup = true;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    exportContactsForAddToMySavedContacts(selectedContacts) {
      let exportContactsParams = [];
      for (let i = 0; i < selectedContacts.length; i++) {
        exportContactsParams.push(selectedContacts[i].Id);
      }
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneSearchContacts/ExportContacts", {contactIds: exportContactsParams})
        .then(() => {
          this.cancelAddToMySavedContacts();
          this.addToMySavedContactsSuccessPopup = true;
          this.$refs.searchContactsRef.searchPaginationSetTimeOut();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    exportCompanies() {
      if (!this.selectedMain.length > 0) {
        this.selecteCompaniesWarningForExportCompaniesPopup = true;
      } else {
        //this.downloadExcelCompaniesPopup = true;
        let arrObj = [];
        for (let i = 0; i < this.selectedMain.length; i++) {
          arrObj.push({
            Id: this.selectedMain[i].Id,
            CompanyId: this.selectedMain[i].CompanyId,
            CompanyLogoUrl: this.selectedMain[i].CompanyLogoUrl,
            CompanyName: this.selectedMain[i].CompanyName,
            LinkedinUrl: this.selectedMain[i].LinkedinUrl,
            Industry: this.selectedMain[i].Industry,
            EmployeeCount: this.selectedMain[i].EmployeeCount,
            City: this.selectedMain[i].City,
            State: this.selectedMain[i].State,
            Country: this.selectedMain[i].Country,
            Zip: this.selectedMain[i].Zip,
          });
        }
        this.downloadCompanies = [{ name: "Companies", data: arrObj }];
      }
    },
    //Address beautification to show in table
    beautifyAddress(value) {
      let contactAddressArr = [];
      contactAddressArr.push(value.ContactCity);
      contactAddressArr.push(value.ContactState);
      contactAddressArr.push(value.ContactCountry);
      let contactAddressFinal = "";
      for (let i = 0; i < contactAddressArr.length; i++) {
        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
          contactAddressFinal += contactAddressArr[i] + ", ";
        }
      }
      return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
    },
    beautifyAddress2(value) {
      let contactAddressArr = [];
      contactAddressArr.push(value.City);
      contactAddressArr.push(value.State);
      contactAddressArr.push(value.Country);
      let contactAddressFinal = "";
      for (let i = 0; i < contactAddressArr.length; i++) {
        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
          contactAddressFinal += contactAddressArr[i] + ", ";
        }
      }
      return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
    },
    decodeJWT() {
      let uid = this.$route.params.id;
      let jwtDecode = VueJwtDecode.decode(
        this.$store.state.authTokenVV[uid].token
      );
      this.initialRole = jwtDecode.initialRole;
    },
    addTagToContacts(paramsContactTag){
      this.addTagsPopup = false;
      let contactTags = [];
      let contactIds = [];
      if(paramsContactTag.contactSelectedTags && paramsContactTag.contactSelectedTags.length > 0){
        paramsContactTag.contactSelectedTags.forEach(element =>{
          contactTags.push(element.TagName);
        })
      }
      if(this.companyDetailPopup){
        this.selectedInner.forEach(element => {
          contactIds.push(element.Id);
        });
      } else {
        this.selectedMain.forEach(element => {
          contactIds.push(element.Id);
        });
      }
      let addContactParams = {
        contactIds:contactIds,
        ContactUserDefinedTags:contactTags,
        IsExport:paramsContactTag.isExportContact,
        ContactSavedFrom:this.tagModuleRequest == 'export' || this.tagModuleRequest =='savecontact' || this.tagModuleRequest =='contactdetails'?'searchcontact':'',
      };
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneSearchContacts/ExportContacts",addContactParams).then((response) => {
        let data = response.data;
        this.addTagsPopup = false;
        this.$refs.addTagToSelectedContact.selectedTag = [];
        if(this.tagModuleRequest == 'export'){
          data.forEach(element => {
            element.ContactUserDefinedTags = element.ContactUserDefinedTags?element.ContactUserDefinedTags.join():'';
          });
          this.downloadContacts = [{ name: "Contacts", data: data }]
          setTimeout(() => {
            if(this.exportContactType == 'csv'){
              this.$refs.selectedToCsv.click();
            } else {
              this.$refs.selectedToExcel.click();
            }
          }, 500);
        }
        if(this.companyDetailPopup){
          this.selectedInner = [];
          this.updateContactsList(data);
        } else {
          this.selectedMain = [];
        }
        if(this.tagModuleRequest == 'contactdetails'){
          this.getContactDetail();
        }
        if(this.tagModuleRequest == 'savecontact'){
          this.addToMySavedContactsSuccessPopup = true
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
      });
    },
    listContactSavedTags(tagDetails = null){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneSearchContacts/ListContactSavedTagsUserDefined").then((response) => {
        let data = response.data;
        this.selectTagsOptions = data;
        if(this.addTagsPopup && tagDetails){
          let newTag = this.selectTagsOptions.filter((x) => (x.TagName) === tagDetails.tagName);
          if(newTag.length){
            this.$refs.addTagToSelectedContact.selectedTag.push(newTag[0]);
          }
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    deleteUserDefinedTag(tagId){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneSearchContacts/DeleteContactSavedTagUserDefined?id="+tagId).then(() => {
        this.listContactSavedTags();
        this.$vs.notify({
          title: "Success",
          text: 'Tag deleted successfully',
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000,
        });
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    async saveUpdateTag(tagDetails){
      this.$vs.loading();
      let input = {
        "Id": tagDetails.tagId,
        "TagName": tagDetails.tagName,
        "IsShared": tagDetails.isTagShared
      }
      await this.axios
        .post("/ws/FindAnyoneSearchContacts/SaveContactSavedTagUserDefined", input)
        .then(() => {
          this.$refs.addUpdateTag.newTagName = null;
          this.$refs.addUpdateTag.isTagShared = false;
          this.$refs.addUpdateTag.tagId = "";
          this.userDefinedTagDetails = null;
          this.addUpdateTagPopup = false;
          this.listContactSavedTags(tagDetails);
          this.$vs.notify({
            title: "Success",
            text: tagDetails.tagId?'Tag updated successfully':'Tag added successfully',
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
    },
    editUserDefinedTag(tagDetails){
      this.userDefinedTagDetails = tagDetails;
      this.addUpdateTagPopup = true;
    },
    closeAddUpdateTagPopup(){
      this.userDefinedTagDetails = null;
      this.$refs.addUpdateTag.newTagName = null;
      this.$refs.addUpdateTag.isTagShared = false;
      this.$refs.addUpdateTag.tagId = "";
      this.addUpdateTagPopup = false;
    },
    addTagToSeleted(){
      let contactSelected = this.companyDetail?this.selectedInner:this.selectedMain;
      if(contactSelected.length){
        this.listContactSavedTags();
        this.addTagsPopup = true;
      } else {
        this.selecteContactWarningForAddToMySavedPopupMessage ="Please select contact to add tags to contact"
        this.selecteContactWarningForAddToMySavedPopup = true;
      }
    },
    removeTagSelected(){
      let contactSelected = this.companyDetail?this.selectedInner:this.selectedMain;
      if(contactSelected.length){
        this.addTagsPopup = true;
        this.listContactSavedTags();
      } else {
        this.selecteContactWarningForAddToMySavedPopupMessage ="Please select contact to renove tags from contact"
        this.selecteContactWarningForAddToMySavedPopup = true;
      }
    },
    removeTagsFromContactConfirmation(removeTags){
      this.addTagsPopup = false;
      this.removeTagPopup = true;
      this.tagRemoveList = removeTags;
    },
    async removeTagsFromContact(){
      this.$vs.loading();
      let contactIds = [];
      let contactTags = [];
      if(this.companyDetailPopup){
        this.selectedInner.forEach(element => {
          contactIds.push(element.Id);
        });
      } else {
        this.selectedMain.forEach(element => {
          contactIds.push(element.Id);
        });
      }
      if(this.tagRemoveList.length > 0){
        this.tagRemoveList.forEach(element =>{
          contactTags.push(element.TagName);
        })
      }

      let input = {
        "ContactUserDefinedTags": contactTags,
        "ContactIds": contactIds
      }
      await this.axios
        .post("/ws/FindAnyoneSearchContacts/RemoveTagFromContacts", input)
        .then(() => {
          this.removeTagPopup = false;
          this.tagRemoveList = null;
          this.$refs.addTagToSelectedContact.selectedTag = [];
          if(this.companyDetailPopup){
            this.selectedInner = [];
          } else {
              this.selectedMain = [];
          }
          this.$vs.notify({
            title: "Success",
            text: 'Tags removed form contact successfully',
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
        });
    },
    editTagsForContact(){
      this.addTagsPopup = true;
      if(this.contactDetails.ContactUserDefinedTags.length){
        this.$refs.addTagToSelectedContact.selectedTag = [];
        this.selectTagsOptions.forEach(ele =>{
          if(this.contactDetails.ContactUserDefinedTags.includes(ele.TagName)){
            this.$refs.addTagToSelectedContact.selectedTag.push(ele);
          }
        });
      }
    },
    updateTagsContactDetails(contactUserTags){
      this.addTagsPopup = false;
      let contactTags = [];
      if(contactUserTags.length > 0){
        contactUserTags.forEach(element =>{
          contactTags.push(element.TagName);
        })
      }
      let addContactParams = {
        Contact_Id:this.contactDetails.Id,
        Tag_Ids:contactTags,
      };
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneSavedContacts/UpdateSavedContactTags",addContactParams).then(() => {
        this.addTagsPopup = false;
        this.$refs.addTagToSelectedContact.selectedTag = [];
        this.getListOfTagsContactDetails();
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
      });
    },
    getListOfTagsContactDetails(){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneSavedContacts/GetSavedContactTags?id="+this.selectedContactId).then((response) => {
        let data = response.data;
        this.contactDetails.ContactUserDefinedTags = data;
        this.$vs.loading.close();
      })
      .catch(e => {
        this.$vs.loading.close();
        this.showError(e);
        this.$vs.notify({
          title: "Erorr",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000
        });
      });
    },
    checkCreditDeductForExport(selectedContacts){
      let creditDeduct = selectedContacts.filter(contact => contact.IsSavedContact == false);
      if(creditDeduct.length >0){
        this.isCreditDeductionEnable = true;
        this.numberOfCreditMessage = "This will deduct " + creditDeduct.length + " credits";
        this.creditWarningPopup = true;
      } else {
        //this.exportContacts(selectedContacts);
        this.listContactSavedTags();
        this.addTagsPopup = true;
        this.tagModuleRequest = 'export';
      }
    },
    updateContactsList(data){
      let contact = this.$refs.searchContactsRef.contacts;
      let results;
      data.forEach(element => {
        results= contact.filter(ele => ele.Id == element.Id);
      });
      results.forEach(ele => {
        ele.IsSavedContact = true;
      });
    },
    searchCompaniesPaginationTimeOut(){
      setTimeout(() => {
        this.searchCompaniesByPagination();
      }, 1000);
    }
  },
};
</script>
